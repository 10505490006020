<template>
  <PageWithLayout>
    <GroupBoxWidget>
      <BoxWidget
        iconName="email"
        iconBgColor="#6CDFC3"
        title="푸시도달"
        :isProgress="true"
        :grid="50"
        :value="0"
        :totalValue="100"
        :isShowTotal="true"
        :isShowPercent="true"/>
      <BoxWidget
        iconName="email"
        iconBgColor="#FFD82A"
        title="푸시오픈"
        :isProgress="true"
        :grid="50"
        :value="0"
        :totalValue="100"
        :isShowTotal="true"
        :isShowPercent="true"/>
    </GroupBoxWidget>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송현황</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="viewModel.model.status | convertIdToText('alarm_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('alarm_status')" />
            </span>
          </td>
          <th>발송일시</th>
          <td>
            {{ viewModel.model.sendAt | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">광고유무</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>광고유무</th>
          <td>
            {{ viewModel.model.isMarketing | convertIdToText('alarm_ad_register') }}
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">타겟설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송타켓</th>
          <td>
            {{ viewModel.model.sendType | convertIdToText('alarm_send_type') }}
          </td>
        </tr>
        <tr v-if="viewModel.model.sendType === 'EXCEL'">
          <th>발송대상 퍼핀유저ID<br>업로드한 엑셀</th>
          <td colspan="2">
            <a class="link_tbl tc_link" :href="viewModel.model.targetPath" target="_blank">{{ viewModel.model.targetPath }}</a>
          </td>
        </tr>
        <tr v-if="viewModel.model.sendType === 'TEST'">
          <th>발송 유저ID 목록</th>
          <td>
            <ul v-for="(targetUserId ,index) in viewModel.model.targetUserList" :key="`cleanTargetUserId${index}`" class="list_td">
              <li>{{ targetUserId }}</li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>플랫폼</th>
          <td>
            {{ viewModel.model.platform | convertIdToText('alarm_platform') }}
          </td>
        </tr>
        <tr>
          <th>회원구분</th>
          <td>
            {{ viewModel.model.targetUserRole | convertIdToText('user_target') }}
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>스케쥴 분할된 유저ID 범위</th>
          <td v-if="viewModel.model.startRange ||  viewModel.model.endRange">{{ viewModel.model.startRange }} ~ {{ viewModel.model.endRange }}</td>
          <td v-else><span class="txt_tbl">-</span></td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">알림 이미지</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>알림 이미지</th>
          <td>
            <img v-if="viewModel.model.image" :src="viewModel.model.image" alt="">
            <span v-else class="txt_tbl">-</span>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">퍼핀 앱내 알림</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.noti_usable"
            :isDisabled="true"
            :isSwitchLeft="false"
            text="앱내 알림 발송" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>앱내 알림 타이틀<span class="txt_info">(채널 구분)</span></th>
          <td>
            <img
              v-if="viewModel.model.noti_group && imgChanel"
              class="img_chanel"
              :src="imgChanel"/>
            <span class="txt_tbl">{{ viewModel.model.noti_group | convertIdToText('alarm_chanel') }}</span>
          </td>
        </tr>
        <tr>
          <th>앱내 알림 본문</th>
          <td>
            <span class="txt_tbl txt_break_all">{{ viewModel.model.noti_contents }}</span></td>
        </tr>
        <tr>
          <th>앱내 알림 랜딩 URL</th>
          <td>
            <span class="txt_tbl txt_break_all">{{
              viewModel.model.noti_scheme
            }}</span>
          </td>
        </tr>
        <tr>
          <th>랜딩 버튼명</th>
          <td>{{ viewModel.model.noti_buttonTitle }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">디바이스 푸시</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.push_usable"
            :isDisabled="true"
            :isSwitchLeft="false"
            text="푸시 발송" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>푸시 타이틀</th>
          <td>{{ viewModel.model.push_title }}</td>
        </tr>
        <tr>
          <th>푸시 본문</th>
          <td>
            <span class="txt_tbl txt_break_all">{{
              viewModel.model.push_contents
            }}</span>
          </td>
        </tr>
        <tr>
          <th>푸시 랜딩</th>
          <td>
            <span class="txt_tbl txt_break_all">{{
              viewModel.model.push_scheme
            }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송일정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>예약 일시</th>
          <td>{{ viewModel.model.sendAt | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="APP_ALARM_LIST"
          :routeQuery="listRouteQuery"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary_border"
          text="내용복제"
          @onClickBtn="viewModel.onClickCopy()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import TimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/timePicker/TimePicker';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertIdToText, getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
// viewModel
import AlarmDetailViewModel from '@/views/app/alarm/viewModel/AlarmDetailViewModel'

export default {
  name:'AlarmDetail',
  components:{
    PageWithLayout,
    TableView,
    GroupBoxWidget,
    BoxWidget,
    Badge,
    IconSvg,
    Radio,
    Selectbox,
    Input,
    SwitchCheck,
    DatePicker,
    TimePicker,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  computed:{
    imgChanel(){
      return getSelectDataList('alarm_chanel').find(item => item.id === this.viewModel.model.noti_group).imgChanel;
    },
    listRouteQuery(){
      let query = this.$route.query;
      if(this.viewModel.model.tabStatus){
        query.status = this.viewModel.model.tabStatus;
      }
      return query
    }
  },
  data(){
    return{
      viewModel: new AlarmDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.list_td li{line-height:22px}
.list_td li:before{display:inline-block;margin:9px 8px 9px 0;width:4px;height:4px;border-radius:100%;background-color:#555;vertical-align:top;content:''}

.tbl_view tbody td .img_chanel{display:inline-block;width:30px;height:30px;margin-right:8px;vertical-align:top;}
.tbl_view tbody td .img_chanel + .txt_tbl{display:inline-block;line-height:16px;vertical-align:top}
</style>